<template>

<div class="divide" />

</template>

<script>

export default {

	props: ['icon', 'text']

}

</script>

<style scoped>

.divide {
	flex-grow: 1;
}

</style>
